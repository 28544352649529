<template>
    <v-data-table fixed-header :items-per-page=-1 hide-default-footer single-select sort-by="timeStamp"
        :sort-desc="true" :height="kioskAnalyticsDimension.qnaAnalyticsheight" :loading="loading" :items="paymentsList"
        :headers="paymentsHeader" item-key="id" single-expand>
        <template v-slot:item.transactionTime="{ item }">
            {{ item.transactionTime | formatStringTime }}
        </template>
    </v-data-table>
</template>
<script>
import { mapGetters } from 'vuex';
import moment from 'moment'
export default {
    name: 'paymentAnalytics',
    props: {
        paymentsList: Array,
        loading: Boolean,
    },
    data() {
        return {
            paymentsHeader: [
                { text: 'Session Id', align: 'start', value: 'sessionId' },
                { text: 'Kiosk Id', value: 'arsKioskId' },
                { text: 'Client Id', value: 'msbClientId' },
                { text: 'Payment Amount', align: '', value: 'transactionAmount' },
                { text: 'Status', value: 'paymentStatus' },
                { text: 'TimeStamp', value: 'transactionTime' }
            ]
        }
    },
    computed: {
        ...mapGetters(["kioskAnalyticsDimension"])
    },
    filters: {
        formatStringTime(val) {
            if (!val) { return '-' }
            return moment(val).format('LTS')
        }
    }
}
</script>